<template>
  <div v-cloak>
    <div v-if="apiLoaded">
      <transition appear appear-active-class="fade-enter-active" name="fade">
        <div class="wrap contest">
          <router-link :to="getBackLink()" class="back-link">
            <v-icon class="icon-item icon-chevron-left"></v-icon>
            Назад
          </router-link>
          <h1>Отчет о результатах направления заявок
            <div class="download-menu">
              <v-menu top>
                <template v-slot:activator="{ on }">
                  <div class="download-menu-icon" v-on="on">
                    <svg
                        fill="none"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          d="M8 17V15H16V17H8ZM16 10L12 14L8 10H10.5V7H13.5V10H16ZM5 3H19C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.9 21 3 20.11 3 19V5C3 3.9 3.9 3 5 3ZM5 5V19H19V5H5Z"
                          fill="#0033A0"
                      />
                    </svg>
                  </div>
                </template>

                <v-list>
                  <v-list-item @click.prevent="downloadReport()">
                    <v-list-item-title>Скачать XLSX</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click.prevent="pdfExport()">
                    <v-list-item-title>Скачать PDF</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <span>В конкурсе «{{ config.title }}»</span>
          </h1>

          <v-tabs>
            <v-tab v-for="(nominationTitle, nominationTitleIndex) in config.nominations"
                   :key="'nominationTitleIndex' + nominationTitleIndex">
              {{nominationTitle.name}}
            </v-tab>
            <v-tab-item v-for="(nomination, nominationIndex) in config.nominations" :key="'nominationIndex' + nominationIndex">
              <v-simple-table
                  fixed-header
                  height="60vh"
                  class="regions-table"
              >
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>Регион</th>
                    <th v-for="(field, fieldIndex) in nomination.fields" :key="fieldIndex + 'invIndex'">{{field.name}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(region, regionIndex) in regionsList" :key="'regionIndex' + regionIndex">
                    <th>{{ region.name }}</th>
                    <template>
                      <td v-for="(field, fieldIndex) in nomination.fields" :key="fieldIndex + 'invIndex'">
                        <template v-if="!isEmpty(field.id, region.code)">
                          <template v-if="field.type === 'FILE'">
                            <a @click="downloadAttachment(getFile(field.id, region.code))">
                              {{ truncateStr(getFile(field.id, region.code).name, 10) }}
                            </a>
                          </template>
                          <template v-else-if="field.type === 'LINK'">
                            <a :href="getValue(field.id, 'VALUE', region.code)?.value" target="_blank">
                              {{ truncateStr(getValue(field.id, 'VALUE', region.code)?.value, 10) }}
                            </a>
                          </template>
                          <template v-else-if="field.type === 'TEXT'">
                            <div>{{ getValue(field.id, 'VALUE', region.code)?.value }}</div>
                          </template>
                        </template>
                        <template v-else>
                          —
                        </template>
                      </td>
                    </template>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-tab-item>
          </v-tabs>
        </div>
      </transition>
    </div>
    <div v-else style="height:2000px"></div>
    <loader-dialog :show="loading" msg="Загрузка"></loader-dialog>
  </div>
</template>

<script>
import api from "@/modules/api";
import {getActiveDomainConfigs, getDomainConfigById} from "@/modules/api.configs";
import {getIndicatorsByContext} from "@/modules/api.indicators";
import {downloadFile} from "@/modules/api.files";
import LoaderDialog from "@/components/elements/LoaderDialog.vue";

export default {
  name: "EventRegionsComponent",
  components: {LoaderDialog},
  props: {},
  data() {
    return {
      apiLoaded: false,
      loading: false,
      config: null,
      domain: "event",
      regionsList: [],
      controllerRoles: ['CONTEST_CONTROLLER', 'ROLE_ADMIN'],
      downloadTaskId: null,
    }
  },

  methods: {
    async loadConfig() {
      const configId = this.$route.params.configId

      let req = configId
          ? await getDomainConfigById("event", configId)
          : await getActiveDomainConfigs("event")
      if (req.ok) {
        this.config = configId
            ? req.payload.content.sections[0]
            : req.payload[0].content.sections[0]
      } else {
        alert('Ошибка загрузки конфигурации')
      }
    },
    async loadValues() {
      let req = await getIndicatorsByContext(this.domain, this.config.context)
      if (req.ok) {
        this.values = req.payload
      } else {
        alert('Ошибка загрузки данных')
      }
    },

    getValue(id, type, regionCode) {
      return this.values.find(e => e.indicatorId === id && e.type === type && e.regionCode === regionCode)
    },
    getFile(id, regionCode) {
      const value = this.getValue(id, 'FILES', regionCode)?.value
      return value ? JSON.parse(value)[0] : null
    },
    isEmpty(id, regionCode) {
      const value = this.getValue(id, 'VALUE', regionCode)?.value
      const files = this.getValue(id, 'FILES', regionCode)?.value

      return !value && (!files || files === '[]');
    },

    async downloadAttachment(attachment) {
      this.loading = true
      await downloadFile(attachment.id, attachment.name)
      this.loading = false
    },

    getBackLink() {
      const configId = this.$route.params.configId
      return configId
          ? '/event/' + configId
          : '/event'
    },

    async downloadReport() {
      try {
        this.loading = true;
        const response = (await api.get(`/reports/generate/${this.domain}/1?report_title=${this.getReportTitle()}&context=${this.config.context}`)).payload;
        this.downloadTaskId = response.task_id;
        await this.checkTaskStatus();
      } catch (error) {
        console.error('Error generating report:', error);
        alert('Ошибка при генерации отчета!');
        this.loading = false;
      }
    },
    async checkTaskStatus() {
      try {
        const response = (await api.get(`/reports/status/${this.downloadTaskId}`)).payload;
        if (response.status === 'SUCCESS') {
          await this.downloadFile();
        } else {
          setTimeout(this.checkTaskStatus, 2000);
        }
      } catch (error) {
        console.error('Error checking task status:', error);
        alert('Ошибка при проверке статуса задачи!');
        this.loading = false;
      }
    },
    async downloadFile() {
      try {
        const blob = (await api.get(`/reports/download/${this.downloadTaskId}`, null, 'blob')).payload;
        this.$downloadBlob(blob, `${this.getReportTitle()}.xlsx`);
      } catch (error) {
        console.error('Error downloading file:', error);
        alert('Ошибка при скачивании файла!');
      } finally {
        this.loading = false;
      }
    },

    getReportTitle() {
        return "Отчет о результатах направления заявок"
    },

    truncateStr(str, n) {
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 7, str.length)
      }
      return str
    },

    checkRoles(userRoles) {
      for (let role of userRoles) {
        if (this.$user.subroles.includes(role)) {
          return true;
        }
      }
      return false;
    },
    async pdfExport() {
      let headerCells = [];
      let rows = [];
      for (let nominationIndex = 0; nominationIndex < this.config.nominations.length; nominationIndex++) {
        let titles = [{caption: "Регион" , width: 4000}];
        let fields = this.config.nominations[nominationIndex].fields;

        titles = titles.concat(fields.map((field) => {return {caption: field.name, width: 4000}}));
        headerCells.push(titles);
        rows.push([]);

        let nominationRow = fields.map(() => {return {cellDataType: "STRING", value: ""}})
        nominationRow.unshift({cellDataType: "STRING", value: this.config.nominations[nominationIndex].name})
        rows[nominationIndex].push(nominationRow);

        this.regionsList.forEach((region, regionIndex) => {
          rows[nominationIndex].push([{cellDataType: "STRING", value: region.name}])
          let value = "Нет";

          fields.forEach(field => {
            if (!this.isEmpty(field.id, region.code)) {
              value = "Да";
            } else {
              value = "Нет";
            }
            rows[nominationIndex][regionIndex + 1].push({cellDataType: "STRING", value: value})
          })
        })
      }
      let query = {
        headerCells: headerCells,
        rows: rows,
      };
      await this.$downloadApi("Отчет о результатах направления заявок - " + this.config.title.substring(0, 50).replaceAll(".", "_"),"/event/report/getPDFReport", query, true);
    },
  },

  filters: {
    dateTimeFormat: function (date) {
      if (date == null) {
        return ''
      }
      const mdate = new Date(date)
      const options = {
        timeZone: 'Europe/Moscow',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
      return mdate.toLocaleDateString('ru-RU', options)
    },
  },

  async beforeMount() {
    if (this.checkRoles(this.controllerRoles)) {
      this.loading = true
      await this.loadConfig()
      await this.loadValues()
      this.regionsList = this.$cznDepartmentsByRegionList.filter(r => this.config.regions.includes(r.code))
      this.regionsList.sort((a, b) => (a.name > b.name ? 1 : -1));
      this.apiLoaded = true;
      this.loading = false;
    }
  },
}

</script>

<style lang="scss">
@import "../../styles/main.scss";
.contest .v-tabs-bar {
  margin-bottom: 60px !important;
}
</style>

<style lang="scss" scoped>
.header-select {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #777779;
    width: 626px;
    max-width: 100%;
  }

  .v-btn {
    margin-left: auto;
    height: 56px;
  }
}

.v-icon {
  color: #0033a0;
}

.v-tabs-items .v-window-item, .v-window__container, .v-item-group {
  overflow-x: auto !important;
}
.regions-table {
  width: 100%;
  overflow: unset;
  thead {
    th {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 160%;
      color: #777779;
      border-bottom: 1px solid #E8E8E9;
      position: sticky;
      top: 0;
      &:first-child {
        text-align: left;
        position: sticky;
        left: 0;
        background: #fff;
        z-index: 5;
      }
    }
  }
  tbody {
    th {
      font-size: 12px;
      font-weight: normal;
      text-align: left;
      border-bottom: 1px solid #E8E8E9;
      padding-right: 20px;
      position: sticky;
      left: 0;
      background: #fff;
    }
    td {
      border-bottom: 1px solid #E8E8E9;
      padding: 20px 5px;
      font-size: 12px;
      text-align: center;

    }

  }

}

</style>
